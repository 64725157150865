import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

import "../scss/pages/index.scss"

const IndexPage = (props) => (
  <Layout template="Home">
    <Seo
        title="Home"
        description={ props.siteDescription }/>

    <section className={ `content-section bg-color-navy` }>
        <div className={ `container-lg inner` }>
            <h1 className={ `text-center` }>
                Welcome to Valley Gem Properties
            </h1>
            <p className={ `intro-statement text-center` }>
                Valley Gem Properties home of Valley Gem Farms, The Farm Store, and other rental properties
            </p>
        </div>
    </section>

    <section className={ `content-section bg-color-navy` }>
        <div className={ `container-xxl inner` }>

            <div className={ `grid-block` }>

                <div className={ `block block-count-3` }>
                    <div className={ `inner` }>
                    <h2>Our Properties</h2>
                    <span>Coming Soon!</span>
                    </div>
                </div>

                <div className={ `block block-count-3` }>
                    <div className={ `inner` }>
                    <h2>The Farm</h2>
                    <span>Coming Soon!</span>
                    </div>
                </div>

                <div className={ `block block-count-3` }>
                    <div className={ `inner` }>
                        <h2>For Sale</h2>
                        <span>From tractors to trinkets.</span>
                        <Link className={ `button` } to="/store">Browse</Link>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section className={ `content-section bg-color-navy text-center` }>
        <div className={ `container-lg inner` }>
            <h2>Get in Touch</h2>
            <img
                src="https://res.cloudinary.com/simplesaint/image/upload/fl_sanitize/ValleyGemProperties/contact-email-light.svg"
                alt="Get In Touch"
                loading="lazy"
                className={ `email-address`}
                width={ `400` }
                height={ `23` }
            />
        </div>
    </section>
  </Layout>
)

export default IndexPage
